// .MuiSvgIcon-root {
//     fill: #F3BA2F !important;
// }

// .MuiTablePagination-input {
//     display: none !important;
// }
// .makeStyles-container-6 {
//     max-height: 715px !important;
// }
// .MuiTableCell-body {
//     color: #fff;
//     background-color: black;
// }

.MuiTableCell-stickyHeader {
  background-color: #3A73C9 !important;
  color: white !important;
}
.background-image-test23 {
  height: 100% !important;
}
.background-image-test2{
  padding-left: 50px;
  padding-right: 50px;
  padding-top:40px;
}
#data-new {
  height: 100% !important;
}
.card-load-sass {
  padding: 10px 0;
  border-radius: 24px;
  width: 210px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.tablebody th,
.tablebody td {
  width: auto !important;
}
.head th,
.head td {
  width: auto !important;
}


@media screen and (max-width: 900px) {
  .background-image-test2{
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .table-res-mobile {
    width: 310% !important;
  }
  .no-data{
    margin-left:6vw !important;
  }
  .background-image-test2{
    padding-left: 0;
    padding-right: 0;
  }
}
