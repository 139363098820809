/*----------------------------------------------------------------------------*/
/*#PADDING STYLE*/
/*----------------------------------------------------------------------------*/


.p--0   { padding:             0!important;}
.p--10  { padding:         10px !important;}
.p--15  { padding:         15px !important;}
.p--20  { padding:         20px !important;}
.p--30  { padding:         30px !important;}
.p--40  { padding:         40px !important;}

.pl--0   { padding-left:    0  !important; }
.pl--10  { padding-left:  10px !important; }
.pl--15  { padding-left:  15px !important; }
.pl--20  { padding-left:  20px !important; }
.pl--30  { padding-left:  30px !important; }
.pl--50  { padding-left:  50px !important; }

.pr--0   { padding-right: 0    !important; }
.pr--10  { padding-right: 10px !important; }
.pr--15  { padding-right: 15px !important; }
.pr--20  { padding-right: 20px !important; }
.pr--30  { padding-right: 30px !important; }
.pr--40  { padding-right: 40px !important; }
.pr--50  { padding-right: 50px !important; }
.pr--55  { padding-right: 55px !important; }

.pb--0  { padding-bottom: 0    !important; }
.pb--10 { padding-bottom: 10px !important; }
.pb--30 { padding-bottom: 30px !important; }
.pb--50 { padding-bottom: 50px !important; }
.pb--60 { padding-bottom: 60px !important; }

.pt--0  { padding-top:       0 !important; }
.pt--20 { padding-top:    20px !important; }
.pt--50 { padding-top:    50px !important; }

// Paddings top/bottom
.ptb--10 {
    padding-top:    10px !important;
    padding-bottom: 10px !important;
}

.ptb--20 {
    padding-top:    20px !important;
    padding-bottom: 20px !important;
}
.ptb--30 { 
    padding-top:    30px !important;
    padding-bottom: 30px !important;
}

/*----------------------------------------------------------------------------*/
/*#MARGIN STYLE*/
/*----------------------------------------------------------------------------*/


.m--0    { margin: 0!important;          }
.m--auto { margin: 0 auto !important;    }
.m--10   { margin: 10px auto !important; }
// Margin top
.mt--0  { margin-top: 0px  !important;   }
.mt--5  { margin-top: 5px  !important;   }
.mt--10 { margin-top: 10px !important;   }
.mt--20 { margin-top: 20px !important;   }
.mt--30 { margin-top: 30px !important;   }
.mt--50 { margin-top: 50px !important;   }
/* Margin left */
.ml--5  { margin-left: 5px    !important;  }
.ml--10 { margin-left: 10px   !important;  }
.ml--20 { margin-left: 20px   !important;  }
.ml--50 { margin-left: 50px   !important;  }
.ml--30 { margin-left: 30px   !important;  }
/* Maring right */
.mr--5  { margin-right: 5px   !important;  }
.mr--20 { margin-right: 20px  !important;  }
.mr--30 { margin-right: 30px  !important;  }
.mr--10 { margin-right: 10px  !important;  }
/* Margin bottom */
.mb--0  { margin-bottom: 0    !important;  }
.mb--5  { margin-bottom: 5px  !important;  }
.mb--10 { margin-bottom: 10px !important;  }
.mb--15 { margin-bottom: 15px !important;  }
.mb--20 { margin-bottom: 20px !important;  }
.mb--25 { margin-bottom: 25px !important;  }
.mb--30 { margin-bottom: 30px !important;  }
.mb--35 { margin-bottom: 35px !important;  }
.mb--40 { margin-bottom: 40px !important;  }
.mb--50 { margin-bottom: 50px !important;  }
.mb--60 { margin-bottom: 60px !important;  }
.mb--70 { margin-bottom: 70px !important;  }



.txt--white { color: #fff; }
.txt--grey  { color: #a5a5a5; }
.txt--black { color: #000; }
.txt--darkgrey  { color: #4a4a4a; }
.txt--lightgrey { 
    color: #354052; 
    opacity: 0.5;
}
.txt--error { color: #e1302a !important };




   /*----------font weight-------------------*/

   .fw--light     { font-weight: 300; }
   .fw--regular   { font-weight: 400; }
   .fw--medium    { font-weight: 500; }
   .fw--semibold  { font-weight: 600; }
   .fw--extrabold { font-weight: 800; }
   .fw--black     { font-weight: 900; }




   .bg--white{
       background-color: white;
   }
   .bg--black{
    background-color: black;
}


.top--70{top: 70px !important;}