.span-txt {
    text-align: center;
    font-size: 20px;
  }
  .new-data {
    background-color: #B6DAEE !important;
    #overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      cursor: pointer;
      display: block;
      border-radius: 2rem;
    }
    .container {
      .color-head {
        font-size: 29px;
      }
      max-width: 1420px;
    }
  }
  .new-btn {
    .btn {
      line-height: 18px !important;
    }
  }
  .reduce_opacity {
    opacity: 0.3;
  }
  