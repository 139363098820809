.home-container {
  background-color: #B6DAEE;
}
.background-image-test {
  height: calc(125% - 70px) !important;
}
.background-image-test {
  p {
    color: black;
    font-weight: bold;
  }

  .new-card-style {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .container {
    .price,
    h4 {
      letter-spacing: 1.3px !important;
      font-size: 19px;
      // line-height: 20px;
    }
  }
}

.dashboard {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: white !important;
  }
}

.disableform {
  color: #fff;
}

.disableform1 {
  background-color: #3c4043 !important;
}

.reduce_opacity {
  opacity: 0.3;
}
.mobile-card {
  padding: 4rem !important;
}
.change-width {
  width: 0 !important;
}
@media screen and (max-width: 500px) {
  .mobile-style {
    width: auto !important;
  }
  .mobile-card {
    padding: 3% !important;
  }
  .change-width {
    width: 100% !important;
  }
  .noticeContainer {
    width: 95% !important;
  }
}

.noticeContainer {
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 20px auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px auto;
}
.noticeContainer p {
  color: #dc3535;
}

// #overlay {
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 2;
//   cursor: pointer;
//   border-radius: 2rem;
// }
