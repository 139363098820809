.sticky-header-container {
  height: 70px;
}

.Nbtn2 {
  background: #3A73C9 !important;
  color: white;
  font-weight: 700;
  width: 115px;
  border: none;
  text-align: center;
}

.Nbtn1 {
  height: 30px;
  background-color: #3A73C9 !important;
  color: white;
  width: 100px;
  border-radius: 5px;
  text-align: center;
}

.header-new {
  background-color: #B6DAEE !important;

  .nav-link{
    color: black !important;
  }
  
  li:last-child {
    border: none;
  }
}

.navigator-container {
  .link {
    color: white;
    text-decoration: none;
  }

  .selected {
    font-weight: bolder;
    text-decoration: underline;
  }
}

.nav-link {
  cursor: pointer;
}

.navbar {
  position: sticky !important;
  top: 0;
  z-index: 100;
}

.navbar-brand {
  cursor: pointer;
}

@media (max-width: 575px) {
  .custom {
    display: none;
  }
}

@media (max-width: 530px) {
  .mobile-styles {
    width: 175px;
    float: right;
    margin: 0 !important;
  }

  .first-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.chainbutton {
  border: 0.704301px solid #00ff3d;
  border-radius: 4.22581px;
  cursor: pointer;
  font-family: Sofia Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13px;
  padding: 10px 50px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  color: white;
  background-color: black;
}

.absolute-container {
  background: #283841;
  display: flex;
  flex-direction: column;
  width: 129px;
  top: 41px;
  color:white;
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;


  span{
    padding-left: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-top: 10px;
  }
}