.card-form-container {
  background-color: #0e1e25;
  overflow: auto;
  .card2 {
    background-color: #001930 !important;
  }
  button {
    width: 400px;
  }

  .heading {
    font-size: 30px;
  }

  .passport-container {
    label {
      color: white;
    }

    .dropzone {
      text-align: center;
      padding: 20px;
      border: 3px dashed #eeeeee;
      background-color: #fafafa;
      color: #bdbdbd;
      width: 400px;
      margin-bottom: 20px;
    }

    // .sigCanvas {
    //   width: 200;
    //   background-color: white;
    // }

    .accept {
      border-color: #107c10 !important;
    }

    .reject {
      border-color: #d83b01 !important;
    }
  }
}

.card-form {
  ul {
    display: block;
  }
  .react-tel-input .country-list {
    margin: 38px 0 10px -1px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: rgb(189, 189, 189) !important;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  input:hover {
    color: white;
  }

  select {
    option {
      color: black I !important;
    }
  }

  .form-control-2 {
    margin-top: 8px;
    background: #001930;
  }

  .form-control-4 {
    margin-top: 4px;
    background: #001930;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .form-control-3 {
    margin-top: 4px;
  }

  // .new-relative input {
  //   background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><text x="5" y="19"  class="caption" style="font: bold 16px Arial;color: white;">+65</text></svg>')
  //     no-repeat !important;
  // }
}

.caption {
  color: white;
}

.note {
  display: flex;
  justify-content: end;
  color: #d83b01;
}

.react-tel-input .form-control {
  background: white !important;
  width: 100% !important;
  margin-top: 9px !important;
  // margin-bottom: 20px !important;
  border: 1px solid #fff;
  border-radius: 7px;
  height: 38px;
}

.react-tel-input .flag-dropdown {
  background: white !important;
  // border: 1px solid #fff !important;
  border-radius: 7px 0 0 7px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}

.card-form-2 input:hover {
  color: black;
}

.passimage {
  height: 200px;
  width: 40%;
}
.mobile-flex {
  align-items: center;
  display: flex;
}
.text-red {
  color: red;
  font-size: 13px;
}
.canvas-res {
  width: 300px;
  height: 130px;
}
.sigCanvas {
  width: 300px;
  height: 130px;
  background-color: #f2f2f2 !important;
}
@media screen and (max-width: 550px) {
  .passimage {
    height: 200px;
    width: 200px;
  }
  .mobile-flex {
    flex-direction: column-reverse;
  }
  .canvas-res {
    width: 300px ;
  }
  .formtoastcon {
    width: 95% ;
    left: auto;
  }
}
@media screen and (max-width: 400px) {
  .canvas-res {
    width: 280px ;
    height: 130px;
  }
  .sigCanvas {
    width: 280px  ;
    height: 130px;
    background-color: #f2f2f2 !important;
  }
}




.formtoastcon {
  width: 300px;
  height: 60px;
  background-color: rgb(222, 7, 7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 7px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 999;
}
