ul {
	display: flex;
	top: 0px;
	z-index: 10;
	padding-bottom: 14px;
}
.color-y {
	color: #2d3f72 !important;
}

.color-head-landing {
	color: black !important;
}
button {
	background-color: #3a73c9 !important;
}
li {
	list-style: none;
	flex: 1;
}
li:last-child {
	border-right: 1px solid #ddd;
}

table {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
}
th {
	display: none;
}

td,
th {
	height: 53px;
}
.padd {
	margin-top: 75px !important;
}
td,
th {
	border: 1px solid #ddd !important;
	padding: 10px;
	empty-cells: show;
	color: #fff;
	background: rgba(0, 0, 0, 0.1);
}

td,
th {
	text-align: left;
}
td + td,
th + th {
	text-align: center;
	display: none;
}
td.default {
	display: table-cell;
}
.bg-purple {
	border-top: 3px solid #0e0e0e !important;
}
.bg-red {
	border-top: 3px solid #ddb345 !important;
}
.bg-blue {
	border-top: 3px solid #3c3e3d !important;
}
.bg-green {
	border-top: 3px solid #eaebed !important;
}
.button {
	width: 100%;
	border: 1px solid #ddd;
	border-top-color: rgb(221, 221, 221);
	border-top-style: solid;
	border-top-width: 1px;
	border-right-color: rgb(221, 221, 221);
	border-right-style: solid;
	border-right-width: 1px;
	border-right: 0;
	border-top: 0;
	padding: 10px;
	background: #fff;
	font-size: 14px;
	font-weight: bold;
	height: 60px;
	color: #999;
}
.sep {
	font-weight: bold;
	color: #f3ba2f;
}
th {
	color: #2d3f72;
}
h1 {
	font-size: 48px;
	text-align: center;
	margin: 30px 0;
}
.txt-l {
	font-size: 28px;
	font-weight: bold;
}
.txt-2 {
	font-size: 18px;
	margin: 10px 0;
}
.txt-top {
	position: relative;
	top: -9px;
	left: -2px;
}
.tick {
	font-size: 18px;
	color: #2ca01c;
}
.hide {
	border: 0 !important;
	background: none;
}

@media (min-width: 640px) {
	ul {
		display: none;
	}
	td,
	th {
		display: table-cell !important;
	}
	td,
	th {
		width: 330px;
	}
	td + td,
	th + th {
		width: auto;
	}
}
.ptext {
	font-size: 13px;
	margin-top: 12px;
	color: #3a73c9;
}
td:first-child {
	font-weight: bold;
	color: #2d3f72;
	overflow: auto;
}
