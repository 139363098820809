// .sidebar {
//     margin: 0;
//     padding: 0;
//     width: 200px;
//     background-color: #f1f1f1;
//     position: fixed;
//     height: 100%;
//     overflow: auto;
//   }
  
//   .sidebar div {
//     display: block;
//     color: black;
//     padding: 16px;
//     text-decoration: none;
//     cursor: pointer;
//   }
//    .affiliate-table-container{
//     width: calc(100% - 200px);
//     float: right;
//    }
.smallcal{
  z-index: 9999 !important;
  height: 20px;
  text-align: center;
  border: 1px solid #d1d3e2;

}
.react-datepicker-popper{
  z-index: 9999 !important;
}
  .sidebar div.active {
    background-color: white;
    color: black !important;
  }
  
  .sidebar div:hover:not(.active) {
    background-color: #B6DAEE;
    color: black;
  }
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }
  .custom-class-con{
   padding: 0px !important;
  }




  .affiliate-table-container {
    width: calc(89% - 194px);
    float: right;
    margin-right: 46px;
    margin-top: 100px;
}

.sidebar div {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
}

.sidebar {
    margin: 0;
    padding: 0;
    width: 276px;
    background-color: white;
    color: black;
    position: fixed;
    height:fit-content;
    overflow: auto;
    margin-top: 100px;
    border: 1px solid #ffffff;
}
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .aff-container{
        margin: 30px;
    }
    .affiliate-table-container{
        width: calc(100%);
        float: right;
        overflow: auto;
        white-space: nowrap;
        margin: 0;
       }
    .sidebar div {float: left;width: 100%;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar div {
      text-align: center;
      float: none;
    }
  }

.subAffi{
    width: calc(100% - 250px) !important;
    float: right;
    margin-top: 23px;
    margin-right: 25px;
}


